import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const LearnMoreCTA = () => {
  return (
    <Wrapper className="learn-more-cta">
      <div className="inner">
        <h2 className="title">
          Learn about the innovation activities taking place in cities.
      </h2>
        <p className="large text">
          Explore how cities around the world are applying innovation methods to tackle their tough challenges.
      </p>
        <div className="actions">
          {/* <Link to="/add-your-city" className="button primary desktop">Add your city to the map</Link> */}
          <Link to="/explore" className="button desktop">Explore the list of cities</Link>
          {/* <Link to="/add-your-city" className="button primary mobile">Add your city</Link> */}
          <Link to="/explore" className="button mobile">Explore cities</Link>
          <Link to="/cities" id="search-cities" className="button">Search</Link>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  margin: 0 -10px;

  body.map-engaged & {
    @media (min-width: ${({ theme }) => theme.desktop}) {
      pointer-events: none;
    }
  }

  .inner {
    background: white;
    padding: 1.875rem 1.25rem 1.25rem;
    box-shadow: 0 2px 10px 0 rgba(98, 101, 110, 0.15);
    border-radius: 12px;
    margin: 0 10px 10px 10px;


    @media (min-width: ${({ theme }) => theme.desktop}) {
      padding: 3.125rem 1.75rem 1.75rem;
      border-radius: 0 0 12px 12px;
      transition: all 0.15s ease;

      body.map-engaged & {
        transform: translate(0%, -100%);
        margin: 0px;
      }
    }
  }

  .title {
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: 0.625rem;

    @media (min-width: ${({ theme }) => theme.desktop}) {
      ${({ theme }) => theme.isHeadingSize['2']}
    }

    @media (min-width: ${({ theme }) => theme.largeDesktop}) {
      ${({ theme }) => theme.isHeadingSize['1']}
      line-height: 1.2;
    }
  }

  .text {
    display: block;
    font-size: 1rem;
    line-height: 1.5;

    @media (min-width: ${({ theme }) => theme.largeDesktop}) {
      font-size: 1.125rem;
      line-height: 1.33;
      padding-right: 2rem;
    }

  }

  .actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1.25rem;
    margin: 1.25rem -5px 0;

    @media (min-width: ${({ theme }) => theme.desktop}) {
      margin: 2.5rem -5px 0;
    }

    .button {
      flex: 1;
      margin: 15px 25px;
    }

    .button[href="/explore"] {
      flex-grow: 2;
    }

    .mobile {
      display: flex;
      @media (min-width: ${({ theme }) => theme.desktop}) {
        display: none;
      }
    }

    .desktop {
      display: none;
      @media (min-width: ${({ theme }) => theme.desktop}) {
        display: flex;
      }
    }
  }
`

export default LearnMoreCTA
