import React from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { Link } from "gatsby"

const ExploreCTA = ({ title, text, link_text, link_href, graphic }) => (
  <Link to={link_href} style={{ textDecoration: "none" }}>
    <Wrapper>
      {graphic && (
        <div
          className="graphic"
          style={{ backgroundImage: `url(${graphic})` }}
        />
      )}
      <div className="cta-content">
        <div className="title">
          <ReactMarkdown source={title} />
        </div>
        <div className="text">
          <ReactMarkdown source={text} />
        </div>
        <div>
          <a className="link-text">{link_text}</a>
        </div>
      </div>
    </Wrapper>
  </Link>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 250px;
  padding: 0.5rem 3.5rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0px 2px 10px 0 rgba(98, 101, 110, 0.06);
  transition: box-shadow 0.15s ease;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin: auto;
    height: auto;
    max-width: 80%;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    padding: 1rem 0.5rem;
    .graphic {
      width: 100%;
      height: auto;
      margin: auto;
    }
  }

  .cta-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    & > div:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .title {
    h2 {
      font-size: 1.25rem;
      line-height: 1.4;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        font-size: 1.5rem;
        line-height: 1.17;
      }
    }
  }

  .graphic {
    height: 100%;
    aspect-ratio: 1/1;
    background: transparent no-repeat center center;
    background-size: contain;
    margin-right: min(120px, 8%);

    &:after {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  .link-text {
    display: inline-block;
    font-weight: bold;
    text-decoration: underline;
    color: ${({ theme }) => theme.darkBlue};
    font-family: ${({ theme }) => theme.fonts["roboto"]};
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 2px 20px 0 rgba(98, 101, 110, 0.1);

    .link-text {
      color: ${({ theme }) => theme.lightBlue};
    }
  }
`

export default ExploreCTA
