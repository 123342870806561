import React from "react"
import useOverview from "../hooks/use-overview"
import get from "lodash/get"
import BarChart from "./BarChart"
import theme from "../styles/theme"
import styled from "styled-components"
import TrackVisibility from "react-on-screen"
import { VictoryPie } from "victory"
import useChartData from "../hooks/use-chart-data"

const FutureChart = ({
  width = 700,
  height = 440,
  mode = "light",
  selectedItems = [],
  barWidth = 8,
  highlightColor = "grey1",
  padding = { left: 30, right: 20, top: 5, bottom: 5 },
  domainPadding = { x: 25, y: 35 },
}) => {

  const colorScale = [
    "#FFF",
    "rgb(189, 210, 201)",
    "rgb(152, 190, 170)",
    "rgb(127, 169, 148)",
    "rgb(94, 145, 120)",
  ]

  const overviewData = useChartData()

  const data = overviewData.budgetPlans.map(val => ({
    x: parseInt(val) + "%",
    y: val,
  }))

  return (
    <TrackVisibility once partialVisibility>
      {({ isVisible }) =>
        isVisible && (
          <>
            <Legend>
              <div>Increase</div>
              <div>Maintain</div>
              <div>Reduce</div>
              <div>Don't know / unclear</div>
              <div>Other</div>
            </Legend>
            <div className="chart-wrapper" style={{ height: height }}>
              <VictoryPie
                animate={{ duration: 500, easing: "polyInOut" }}
                width={width}
                height={height}
                colorScale={colorScale}
                data={data}
                style={{
                  labels: { fill: "white" },
                  parent: {
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    left: 0,
                    top: 0,
                    touchAction: "auto",
                  },
                }}
              />
            </div>
          </>
        )
      }
    </TrackVisibility>
  )
}

const Legend = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  row-gap: 12px;
  font-family: ${({ theme }) => theme.fonts["roboto"]};
  font-size: 0.85rem;
  font-weight: 600;
  div {
    margin-left: 8px;
    padding-left: 24px;
    position: relative;
    font-size: 1em;
  }

  div::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  div:nth-child(1)::before {
    background-color: rgb(255, 255, 255);
  }

  div:nth-child(2)::before {
    background-color: rgb(189, 210, 201);
  }

  div:nth-child(3)::before {
    background-color: rgb(152, 190, 170);
  }

  div:nth-child(4)::before {
    background-color: rgb(127, 169, 148);
  }

  div:nth-child(5)::before {
    background-color: rgb(94, 145, 120);
  }
`

const styles = {
  light: {
    yAxis: {
      axisLabel: {
        fontFamily: "Roboto Condensed",
        fill: "white",
        padding: 30,
        x: -10,
      },
      axis: {
        stroke: "white",
      },
      grid: {
        stroke: "#dddee0",
        opacity: "0.55",
        strokeWidth: 1,
      },
      tickLabels: {
        fill: "white",
        fontSize: 12,
        fontFamily: "Roboto Condensed",
        fontWeight: "bold",
      },
    },
    xAxis: {
      axis: {
        stroke: "white",
      },
      grid: {
        stroke: "transparent",
      },
      tickLabels: {
        fill: "white",
        fontSize: 12,
        fontFamily: "Roboto Condensed",
        fontWeight: "bold",
      },
    },
  },
  dark: {
    yAxis: {
      axis: {
        stroke: "#dddee0",
      },
      grid: {
        stroke: "#dddee0",
        opacity: "0.55",
        strokeWidth: 1,
      },
      tickLabels: {
        fill: theme.grey1,
        fontSize: 12,
        fontFamily: "Roboto Condensed",
        fontWeight: "bold",
      },
    },
    xAxis: {
      axis: {
        stroke: "#dddee0",
      },
      grid: {
        stroke: "transparent",
      },
    },
  },
}

export default FutureChart
