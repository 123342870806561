import React from "react"
import styled from "styled-components"
import get from "lodash/get"
import map from "lodash/map"
import TrackVisibility from "react-on-screen"
import PercentCircle from "./PercentCircle"
import usePercentages from "../hooks/use-percentages"

const SkillsPercentChart = () => {
  const topSkills = get(usePercentages(), 'top_skills') || []
  return (
    <TrackVisibility once partialVisibility>
      {({ isVisible }) => isVisible && (
        <Wrapper>
          {map(topSkills, (skill, i) => {
            return (
              <div key={`skill-${i}`}>
                <PercentCircle
                  percent={skill.percent}
                  total={skill.num_cities}
                  totalCities={skill.total_num_cities}
                />
                <p>{skill.title}</p>
              </div>
            )
          })}
        </Wrapper>
      )}
    </TrackVisibility>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 500px;

  & > * {
    flex-basis: 178px;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }

  p {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: bold;
    font-family: 'Roboto';
    line-height: 1.33;
    letter-spacing: 0.35px;
    margin: 1rem 0 1.5rem;
  }
`

export default SkillsPercentChart
