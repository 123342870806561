import React, { useEffect } from "react"
import { graphql } from "gatsby"
import debounce from "lodash/debounce"
import orderBy from "lodash/orderBy"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"

import Layout from "../components/Layout"
import CityCard from "../components/CityCard"
import SkillsPercentChart from "../components/SkillsPercentChart"
import SEO from "../components/seo"
import PageIntro from "../components/PageIntro"
import ExploreCTA from "../components/ExploreCTA"
import CustomLink from "../components/CustomLink"
import CityMap from "../components/CityMap"
import LocationPin from "../components/styled-components/LocationPin"
import PoliciesBarChart from "../components/PoliciesBarChart"
import AreasInnovationChart from "../components/AreasInnovationChart"
import FactorsChart from "../components/FactorsChart"
import ChallengesChart from "../components/ChallengesChart"
import FutureChart from "../components/FutureChart"
import FuturePieChart from "../components/FuturePieChart"
import ActivityRadarChart from "../components/ActivityRadarChart"
import LearnMoreCTA from "../components/LearnMoreCTA"
import ResponsiveChart from "../components/ResponsiveChart"
import FilterControls from "../components/FilterControls"
import FilterCitiesProvider from "../providers/FilteredCitiesProvider"

import useCities from "../hooks/use-cities"
import useGeneralSettings from "../hooks/use-general-settings"
import KeyFindings from "../components/KeyFindings"

const SHOW_ABOUT_SECTION = false

export const IndexPageTemplate = ({
  children,
  page_intro = {},
  explore_ctas = [],
  about_the_report,
  skills_overview = {},
  policies_overview = {},
  areas_innovation_overview = {},
  factors_overview = {},
  challenges_overview = {},
  future_overview = {},
  activities_overview = {},
  city_list = {},
  renderMap = true,
  isPreview = false,
  downloadReportUrl,
}) => (
  <HomeWrapper isPreview={false}>
    {!isPreview && (
      <div className="learn-more">
        <div className="container">
          <LearnMoreCTA />
        </div>
      </div>
    )}
    {renderMap && (
      <div className="page-header">
        <div className="map-header">
          <div className="map-container" id="map">
            <CityMap />
          </div>
        </div>
        <div className="filter-controls">
          <div className="container">
            <FilterControls />
          </div>
        </div>
      </div>
    )}
    <section className="home-intro">
      <div className="container">
        <div className="inner-container">
          <div className="columns ">
            <div className="column column-7">
              <div className="home-intro__text">
                <PageIntro
                  {...page_intro}
                  buttons={
                    [
                      // {
                      //   class: "primary",
                      //   text: "Download report",
                      //   href: downloadReportUrl || "/"
                      // }
                    ]
                  }
                />
              </div>
            </div>
            <div className="column column-3">
              <div className="home-intro__authors">
                <p>Developed By</p>
                <div className="home-intro__authors__list">
                  <a
                    className="home-intro__authors__list__item"
                    href="https://www.bloomberg.org/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      src="/img/BloombergPhilanthropies.png"
                      alt="Bloomberg Philanthropies Logo"
                    />
                  </a>
                  <a
                    className="home-intro__authors__list__item"
                    href="https://www.oecd.org"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src="/img/OECD.png" alt="OECD Logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="overviews">
      <section className="overview overview--key-findings">
        {!isPreview && <KeyFindings isPreview={isPreview} />}
      </section>
      <section className="overview overview--site">
        <div className="home-cta">
          <div className="container mobile-full-bleed">
            <div className="inner-container">
              <div className="rows">
                {explore_ctas
                  .filter(e => e.link_href !== "/add-your-city")
                  .map((cta, index) => {
                    // {explore_ctas.map((cta, index) => {
                    return index < 3 ? (
                      <div
                        className="column column-9"
                        key={`explore-cta-${index}`}
                      >
                        {/* <div className="column column-4" key={`explore-cta-${index}`}> */}
                        <ExploreCTA {...cta} />
                      </div>
                    ) : null
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="overview overview--skills">
        <div className="overview__background" />
        <div className="overview__inner">
          <div className="overview__flag left" />
          <div className="container">
            <div className="">
              <div className="columns">
                <div className="column column-8">
                  <div className="overview-block">
                    <div className="overview-block__inner">
                      {isPreview ? (
                        <div className="chart-placeholder" />
                      ) : (
                        <SkillsPercentChart />
                      )}
                    </div>
                  </div>
                </div>
                <div className="column column-4">
                  <div className="overview__text">
                    <div className="overview__pin">
                      <LocationPin className="green" />
                    </div>
                    <ReactMarkdown source={skills_overview.title} />
                    <div className="large">
                      <ReactMarkdown source={skills_overview.subtitle} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="overview overview--policies">
        <div className="overview__background" />
        <div className="overview__inner">
          <div className="overview__flag right" />
          <div className="container">
            <div className="">
              <div className="columns">
                <div className="column column-4">
                  <div className="overview__text">
                    <div className="overview__pin">
                      <LocationPin className="purple" />
                    </div>
                    <ReactMarkdown source={policies_overview.title} />
                    <div className="large">
                      <ReactMarkdown source={policies_overview.subtitle} />
                    </div>
                  </div>
                </div>
                <div className="column column-8">
                  <div className="overview-block">
                    <div className="overview-block__chart">
                      {isPreview ? (
                        <div className="chart-placeholder" />
                      ) : (
                        <ResponsiveChart
                          initialWidth={700}
                          initialHeight={420}
                          delay={250}
                          mobileAspectRatio={0.78}
                        >
                          {({ width, height }) => (
                            <PoliciesBarChart
                              width={width}
                              height={height}
                              barWidth={17}
                            />
                          )}
                        </ResponsiveChart>
                      )}
                      {policies_overview.chart_subtitle && (
                        <p className="overview-block__chart__text">
                          {policies_overview.chart_subtitle}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="overview overview--areas-innovation">
        <div className="overview__background" />
        <div className="overview__inner">
          <div className="overview__flag left" />
          <div className="container">
            <div className="">
              <div className="columns">
                <div className="column column-8">
                  <div className="overview-block">
                    <div className="overview-block__chart">
                      {isPreview ? (
                        <div className="chart-placeholder" />
                      ) : (
                        <ResponsiveChart
                          initialWidth={700}
                          initialHeight={420}
                          delay={250}
                          mobileAspectRatio={0.78}
                        >
                          {({ width, height }) => (
                            <AreasInnovationChart
                              width={width}
                              height={height}
                            />
                          )}
                        </ResponsiveChart>
                      )}
                      {areas_innovation_overview.chart_subtitle && (
                        <p className="overview-block__chart__text">
                          {areas_innovation_overview.chart_subtitle}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="column column-4">
                  <div className="overview__text">
                    <div className="overview__pin">
                      <LocationPin className="dark-blue" />
                    </div>
                    <ReactMarkdown source={areas_innovation_overview.title} />
                    <div className="large">
                      <ReactMarkdown
                        source={areas_innovation_overview.subtitle}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="overview overview--factors">
        <div className="overview__background" />
        <div className="overview__inner">
          <div className="overview__flag right" />
          <div className="container">
            <div className="">
              <div className="columns">
                <div className="column column-4">
                  <div className="overview__text">
                    <div className="overview__pin">
                      <LocationPin className="light-green" />
                    </div>
                    <ReactMarkdown source={factors_overview.title} />
                    <div className="large">
                      <ReactMarkdown source={factors_overview.subtitle} />
                    </div>
                  </div>
                </div>
                <div className="column column-8">
                  <div className="overview-block">
                    <div className="overview-block__chart">
                      {isPreview ? (
                        <div className="chart-placeholder" />
                      ) : (
                        <ResponsiveChart
                          initialWidth={700}
                          initialHeight={420}
                          delay={250}
                          mobileAspectRatio={0.78}
                        >
                          {({ width, height }) => (
                            <FactorsChart
                              width={width}
                              height={height}
                              barWidth={17}
                            />
                          )}
                        </ResponsiveChart>
                      )}
                      {factors_overview.chart_subtitle && (
                        <p className="overview-block__chart__text">
                          {factors_overview.chart_subtitle}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="overview overview--challenges">
        <div className="overview__background" />
        <div className="overview__inner">
          <div className="overview__flag left" />
          <div className="container">
            <div className="">
              <div className="columns">
                <div className="column column-8">
                  <div className="overview-block">
                    <div className="overview-block__chart">
                      {isPreview ? (
                        <div className="chart-placeholder" />
                      ) : (
                        <ResponsiveChart
                          initialWidth={700}
                          initialHeight={420}
                          delay={250}
                          mobileAspectRatio={0.78}
                        >
                          {({ width, height }) => (
                            <ChallengesChart
                              width={width}
                              height={height}
                              barWidth={17}
                            />
                          )}
                        </ResponsiveChart>
                      )}
                      {challenges_overview.chart_subtitle && (
                        <p className="overview-block__chart__text">
                          {challenges_overview.chart_subtitle}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="column column-4">
                  <div className="overview__text">
                    <div className="overview__pin">
                      <LocationPin className="pink" />
                    </div>
                    <ReactMarkdown source={challenges_overview.title} />
                    <div className="large">
                      <ReactMarkdown source={challenges_overview.subtitle} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="overview overview--future">
        <div className="overview__background" />
        <div className="overview__inner">
          <div className="overview__flag right" />
          <div className="container">
            <div className="">
              <div className="columns">
                <div className="column column-4">
                  <div className="overview__text">
                    <div className="overview__pin">
                      <LocationPin className="dark-green" />
                    </div>
                    <ReactMarkdown source={future_overview.title} />
                    <div className="large">
                      <ReactMarkdown source={future_overview.subtitle} />
                    </div>
                  </div>
                </div>
                <div className="column column-8">
                  <div className="overview-block">
                    <div className="overview-block__chart">
                      {isPreview ? (
                        <div className="chart-placeholder" />
                      ) : (
                        <>
                          <ResponsiveChart
                            initialWidth={700}
                            initialHeight={420}
                            delay={250}
                            mobileAspectRatio={0.78}
                          >
                            {({ width, height }) => (
                              <FutureChart
                                width={width}
                                height={height}
                                barWidth={17}
                              />
                            )}
                          </ResponsiveChart>
                          {future_overview.chart_subtitle_1 && (
                            <p className="overview-block__chart__text">
                              {future_overview.chart_subtitle_1}
                            </p>
                          )}
                          <ResponsiveChart
                            initialWidth={700}
                            initialHeight={420}
                            delay={250}
                            mobileAspectRatio={0.78}
                          >
                            {({ width, height }) => (
                              <FuturePieChart
                                width={width}
                                height={height}
                                barWidth={17}
                              />
                            )}
                          </ResponsiveChart>
                          {future_overview.chart_subtitle_2 && (
                            <p className="overview-block__chart__text">
                              {future_overview.chart_subtitle_2}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    {about_the_report && SHOW_ABOUT_SECTION && (
      <section className="home-about">
        <div className="container">
          <div className="columns centered">
            <div className="column column-6">
              <div className="content">
                <h3>{about_the_report.title}</h3>
                <ReactMarkdown source={about_the_report.text} />
              </div>
            </div>
          </div>
        </div>
      </section>
    )}
  </HomeWrapper>
)

const IndexPage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  const cities = useCities()
  const sortedCities = orderBy(cities, ["title"], ["asc"])
  const { download_report_url } = useGeneralSettings()

  useEffect(() => {
    const trackScroll = debounce(() => {
      const { scrollY } = window
      if (scrollY > 5) {
        document.body.classList.add("is-scrolled")
      } else if (scrollY <= 5) {
        document.body.classList.remove("is-scrolled")
      }
    }, 5)
    window.addEventListener("scroll", trackScroll)
    return () => {
      window.removeEventListener("scroll", trackScroll)
    }
  }, [])

  return (
    <Layout className="home">
      <SEO />
      <IndexPageTemplate
        {...frontmatter}
        downloadReportUrl={download_report_url}
      ></IndexPageTemplate>
    </Layout>
  )
}

const HomeWrapper = styled.div`
  transition: padding-top 0.15s;
  padding-top: ${({ isPreview }) => (isPreview ? "0" : "18rem")};

  @media (min-width: ${({ theme }) => theme.mobile}) {
    padding-top: ${({ isPreview }) => (isPreview ? "0" : "15rem")};
  }

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding-top: ${({ isPreview }) => (isPreview ? "0" : "8.75rem")};

    body.is-scrolled & {
      padding-top: 4.375rem;
    }
  }

  .chart-wrapper {
    position: relative;
  }

  .learn-more {
    position: absolute;
    top: 4.5rem;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 1000;

    .container {
      height: 0;
    }

    @media (min-width: ${({ theme }) => theme.desktop}) {
      top: 0;
      .learn-more-cta {
        max-width: 60%;
      }
    }

    @media (min-width: ${({ theme }) => theme.largeDesktop}) {
      .learn-more-cta {
        max-width: 580px;
      }
    }
  }

  .page-header {
    .map-header {
      position: relative;
      height: 24rem;
      background: white;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        height: 27rem;
      }

      @media (min-width: ${({ theme }) => theme.tablet}) {
        height: 43rem;
      }

      .map-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

    .filter-controls {
      position: relative;
      width: 100%;
      z-index: 3;

      .container {
        padding: 0;

        @media (min-width: ${({ theme }) => theme.tablet}) {
          height: 0;
          padding: 0 3.125rem;
        }

        .filter-controls-wrapper {
          @media (min-width: ${({ theme }) => theme.tablet}) {
            transform: translate(0, calc(-100% - 1.75rem));

            body.is-ie & {
              transform: translate(0, -100%);
              margin-top: -1.75rem;
            }
          }
        }
      }
    }
  }

  .home-intro {
    padding: 2.8125rem 0 2rem;
    background: #f6f6f6;

    @media (min-width: ${({ theme }) => theme.mobile}) {
      background: white;
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
      padding: 5rem 0;
      background: white;
    }

    .column {
      margin-bottom: 1.25rem;
    }

    p.large,
    .large p {
      font-size: 1.25rem;
      line-height: 1.6;
      letter-spacing: normal;

      @media (min-width: ${({ theme }) => theme.tablet}) {
        font-size: 1.875rem;
        line-height: 1.33;
        letter-spacing: -0.2px;
      }
    }

    &__text {
      @media (min-width: ${({ theme }) => theme.desktop}) {
        padding-right: 2.5rem;
      }
    }

    &__authors {
      @media (min-width: ${({ theme }) => theme.desktop}) {
        padding-left: 2.5rem;
      }

      &__list {
        display: flex;
        justify-content: flex-start;
        margin-top: 1.25rem;

        @media (min-width: ${({ theme }) => theme.desktop}) {
          justify-content: space-between;
        }

        &__item {
          display: block;
          height: 2.25rem;
          margin-right: 1.25rem;
          text-decoration: none;

          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }
  }

  .home-cta {
    .column {
      margin-bottom: 1rem;
    }

    &__card {
      height: 100%;
      padding: 2.5rem 0 3.5rem;
      text-align: center;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__graphic {
        width: calc(100% - 2.5rem);
        margin: 1rem auto;
        background: ${({ theme }) => theme.grey1};
        border-radius: 50%;

        &:after {
          content: "";
          display: block;
          padding-top: 100%;
        }
      }

      a {
        font-weight: bold;
        text-decoration: underline;
        color: ${({ theme }) => theme.darkBlue};
      }

      &__text {
        ${({ theme }) => theme.hideBreaks()}
        width: calc(100% - 1.5rem);
        margin: 0 auto;
      }
    }
  }

  .cities {
    padding-top: 8rem;
    margin-top: -8rem;

    &__inner {
      padding: 2rem 0 2.5rem;
      background: ${({ theme }) => theme.offWhite};

      @media (min-width: ${({ theme }) => theme.mobile}) {
        padding: 3rem 0 5rem;
      }
    }

    &__title {
      margin-bottom: 1.5rem;
      text-align: left;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        margin-bottom: 2rem;
        text-align: center;
      }
    }
  }

  .home-about {
    padding: 2rem 0;

    @media (min-width: ${({ theme }) => theme.tablet}) {
      padding: 5rem 0;
    }

    .button {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      @media (min-width: ${({ theme }) => theme.tablet}) {
        min-width: 11.25rem;
        margin-left: 1rem;
        margin-right: 0.5rem;
      }
    }
  }

  .overview-block {
    ${({ theme }) => theme.hideBreaks()}
    height: 100%;
    background: purple;
    text-align: center;
    border-radius: 12px;
    padding: 2.5rem 1rem;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      margin-left: -20px;
      margin-right: -20px;
      padding: 1.75rem 20px;
      border-radius: 0;
    }

    &__title {
      margin-bottom: 0.875rem;
    }

    &__subtitle {
      &,
      p {
        font-size: 1.375rem;
      }
    }

    * {
      color: white;
    }

    &__chart {
      width: 100%;
    }

    &__footer {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .city-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.125rem;

    @media (min-width: 500px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${({ theme }) => theme.desktop}) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: ${({ theme }) => theme.largeDesktop}) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    * {
      text-decoration: none;
    }

    body.is-ie & {
      margin: 0 -0.5625rem;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 0;
        clear: both;
      }

      .city-list__item {
        margin-bottom: 1.125rem;

        @media (min-width: ${({ theme }) => theme.mobile}) {
          width: 50%;
          float: left;
          padding: 0 0.5625rem;
        }

        @media (min-width: ${({ theme }) => theme.tablet}) {
          width: 33.33%;
        }

        @media (min-width: ${({ theme }) => theme.desktop}) {
          width: 25%;
        }
      }
    }
  }

  .overviews {
    padding: 0;
    background: rgba(90, 136, 159, 0.05);
    overflow: hidden;
    z-index: 1;

    @media (min-width: ${({ theme }) => theme.mobile}) {
      padding: 3rem 0;
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
      padding: 6.25rem 0;
    }

    .overview {
      position: relative;
      margin-bottom: 3.5rem;
      z-index: 1;

      @media (min-width: ${({ theme }) => theme.tablet}) {
        margin-bottom: 8.5rem;
      }

      &__text {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
      }

      &__background {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        @media (min-width: 1129px) {
          display: block;
        }
      }

      &__flag {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: calc(100% - 1.25rem);
        z-index: -1;

        &.left {
          right: auto;
          left: 0;
        }

        &.right {
          right: 0;
          left: auto;
        }

        @media (min-width: ${({ theme }) => theme.tablet}) {
          display: block;
        }
      }

      &:first-child {
        margin-bottom: 6.25rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &__text {
        .large p {
          font-size: 1rem;
          line-height: 1.45;
          letter-spacing: normal;
          margin: 0.75rem 0;

          @media (min-width: ${({ theme }) => theme.tablet}) {
            font-size: 1.375rem;
          }
        }
      }

      &__pin {
        position: relative;
        width: 1.5rem;
        height: 3rem;
        margin-bottom: 1rem;

        @media (min-width: ${({ theme }) => theme.tablet}) {
          margin-bottom: 3.125rem;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 2rem;
          height: 3px;
          left: 50%;
          bottom: 0;
          mix-blend-mode: multiply;
          -webkit-filter: blur(2px);
          filter: blur(2px);
          background: ${({ theme }) => theme.grey1};
          opacity: 0.2;
          transform: translate(-50%, 0);
          border-radius: 1.5px;
        }
      }

      &--site {
        z-index: 2;
      }

      &--key-findings {
        .overview__background {
          top: -6.5rem;
          height: calc(100% + 22rem);
          background: transparent url(/img/purple-wave.svg) no-repeat left
            center;
          background-size: auto 100%;
        }
        .overview__text {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            padding-left: 5rem;
          }
        }
        .overview-block {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            margin-left: 1.75rem;
          }
        }

        .overview__flag {
          background-color: rgb(81, 60, 117);
        }
      }

      &--skills {
        .overview__background {
          top: -1.5rem;
          height: calc(100% + 22rem);
          background: transparent url(/img/teal-wave.svg) no-repeat left center;
          background-size: auto 100%;
          transform: scaleX(-1);
        }
        .overview__text {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            padding-left: 5rem;
          }
        }
        .overview-block {
          background: ${({ theme }) => theme.greenBlue};
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            margin-left: 1.75rem;
          }
        }

        .overview__flag {
          background-color: ${({ theme }) => theme.greenBlue};
        }
      }

      &--activities {
        .column-a {
          order: 2;

          @media (min-width: ${({ theme }) => theme.tablet}) {
            order: 1;
          }
        }

        .column-b {
          order: 1;

          @media (min-width: ${({ theme }) => theme.tablet}) {
            order: 2;
          }
        }

        .overview-block {
          background: ${({ theme }) => theme.darkBlue};

          &__inner {
            position: relative;
          }
        }

        .overview__flag {
          background-color: ${({ theme }) => theme.darkBlue};
        }

        .overview__background {
          top: 50%;
          height: calc(100% + 18rem);
          background: transparent url(/img/blue-wave.svg) no-repeat right center;
          background-size: auto 100%;
        }

        .overview__text {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            padding-left: 2.5rem;
          }
        }
      }

      &--policies {
        .overview__background {
          top: -12.5rem;
          height: calc(100% + 22rem);
          background: transparent url(/img/purple-wave.svg) no-repeat left
            center;
          background-size: auto 100%;
        }
        .overview__text {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            padding-right: 5rem;
          }
        }
        .overview-block {
          padding: 4rem 2.5rem 3.125rem;
          background: ${({ theme }) => theme.purple};

          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            margin-left: 1.75rem;
          }
        }

        .overview__flag {
          background-color: ${({ theme }) => theme.purple};
        }

        .overview-block__chart__text {
          text-align: left;
          font-weight: bold;
          margin-top: 0.625rem;
          padding-left: 1.75rem;
        }
      }

      &--areas-innovation {
        .overview__background {
          height: calc(100% + 22rem);
          background: transparent url("/img/Forme bleu fonce.png") no-repeat
            right center;
          background-size: auto 100%;
        }
        .overview__text {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            padding-left: 5rem;
          }
        }
        .overview-block {
          padding: 4rem 2.5rem 3.125rem;
          background: rgb(85, 122, 159);

          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            margin-left: 1.75rem;
          }
        }

        .overview__flag {
          background-color: rgb(85, 122, 159);
        }

        .overview-block__chart__text {
          text-align: center;
          font-weight: bold;
          margin-top: 0.625rem;
        }
      }

      &--factors {
        .overview__background {
          z-index: -1;
          top: -9.75rem;
          height: calc(100% + 8.5rem);
          background: transparent url("/img/Forme verte.png") no-repeat left
            center;
          background-size: auto 100%;
        }
        .overview__text {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            padding-right: 5rem;
          }
        }
        .overview-block {
          padding: 4rem 2.5rem 3.125rem;
          background: rgb(130, 182, 138);

          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            margin-left: 1.75rem;
          }
        }

        .overview__flag {
          background-color: rgb(130, 182, 138);
        }

        .overview-block__chart__text {
          text-align: center;
          font-weight: bold;
          margin-top: 0.625rem;
        }
      }

      &--challenges {
        .overview__background {
          height: calc(100% + 9.75rem);
          background: transparent url("/img/Forme rouge.png") no-repeat right
            center;
          background-size: auto 100%;
        }
        .overview__text {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            padding-left: 5rem;
          }
        }
        .overview-block {
          padding: 4rem 2.5rem 3.125rem;
          background: rgb(220, 79, 109);

          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            margin-left: 1.75rem;
          }
        }

        .overview__flag {
          background-color: rgb(220, 79, 109);
        }

        .overview-block__chart__text {
          text-align: center;
          font-weight: bold;
          margin-top: 0.625rem;
        }
      }

      &--future {
        .overview__background {
          top: 0;
          height: calc(100% + 22rem);
          background: transparent url("/img/Forme verte fonce.png") no-repeat
            left center;
          background-size: 100% 100%;
        }
        .overview__text {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            padding-right: 5rem;
          }
        }
        .overview-block {
          padding: 4rem 2.5rem 3.125rem;
          background: rgb(78, 133, 104);

          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            margin-left: 1.75rem;
          }
        }

        .overview__flag {
          background-color: rgb(78, 133, 104);
        }

        .overview-block__chart__text {
          text-align: center;
          font-weight: bold;
          margin-top: 0.625rem;
          &:nth-child(2) {
            margin-bottom: 8.75rem;
          }
        }
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        page_intro {
          subtitle
          text
          buttons {
            text
            href
            class
          }
        }
        explore_ctas {
          title
          text
          link_text
          link_href
          graphic
        }
        skills_overview {
          title
          subtitle
        }
        policies_overview {
          title
          subtitle
          chart_subtitle
        }
        areas_innovation_overview {
          title
          subtitle
          chart_subtitle
        }
        factors_overview {
          title
          subtitle
          chart_subtitle
        }
        challenges_overview {
          title
          subtitle
          chart_subtitle
        }
        future_overview {
          title
          subtitle
          chart_subtitle_1
          chart_subtitle_2
        }
        activities_overview {
          title
          subtitle
        }
        about_the_report {
          title
          text
        }
        city_list {
          title
        }
      }
    }
  }
`

export default IndexPage
