import React, { useState, useEffect, createRef } from "react"
import useOverview from "../hooks/use-overview"
import get from "lodash/get"
import BarChart from "./BarChart"
import theme from "../styles/theme"
import styled from "styled-components"
import TrackVisibility from "react-on-screen"
import result from "lodash/result"
import {
  VictoryChart,
  VictoryBar,
  VictoryStack,
  VictoryAxis,
  VictoryLabel,
  VictoryTooltip,
} from "victory"
import useChartData from "../hooks/use-chart-data"

const GraphTooltip = props => {
  const { datum, x, y, highlightColor } = props
  const [offset, updateOffset] = useState(null)
  const elementRef = createRef()
  useEffect(() => {
    const windowWidth =
      typeof window !== "undefined" ? get(window, "innerWidth") : null
    const bounds = result(elementRef.current, "getBoundingClientRect")
    if (bounds && offset === null) {
      if (bounds.x < 0) {
        updateOffset(Math.abs(bounds.x))
        return
      }
      if (windowWidth) {
        if (bounds.x + bounds.width > windowWidth) {
          updateOffset(windowWidth - (bounds.x + bounds.width + 16))
          return
        }
      }
      updateOffset(0)
    }
  }, [elementRef, offset])
  const currentOffset = offset === null ? 0 : offset
  return (
    <g style={{ pointerEvents: "none", opacity: offset === null ? 0 : 1 }}>
      <foreignObject
        x={x - 150 + currentOffset}
        y={y - 110}
        width="300"
        height="115"
        ref={elementRef}
      >
        <GraphTooltipWrapper
          className="graph-tooltip"
          offset={currentOffset}
          highlightColor={highlightColor}
        >
          <div className="graph-tooltip__inner">
            <div className="title">{`${datum.y}% of cities`}</div>
            <div className="text">Lacks of {datum.x}</div>
          </div>
        </GraphTooltipWrapper>
      </foreignObject>
    </g>
  )
}

const GraphTooltipWrapper = styled.div`
  &,
  .graph-tooltip {
    position: relative;
    padding: 5px;
    text-align: left;

    &__inner {
      width: 100%;
      height: calc(100% - 20px);
      padding: 1.25rem;
      border-radius: 12px;
      box-shadow: 0 2px 5px 0 rgba(98, 101, 110, 0.3);
      background: white;
    }

    .title {
      font-family: ${({ theme }) => theme.fonts["roboto"]};
      font-size: 1.125rem;
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: 0.34px;
      color: ${({ theme, highlightColor }) =>
        theme[highlightColor] || theme.grey1} !important;
      margin-bottom: 10px;
    }

    .text {
      font-family: ${({ theme }) => theme.fonts["vollkorn"]};
      font-size: 0.875rem;
      line-height: 1.43;
      letter-spacing: -0.09px;
      color: ${({ theme }) => theme.grey1} !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:after {
      display: block;
      position: absolute;
      content: "";
      width: 0px;
      height: 0px;
      bottom: -15px;
      left: calc(50% - 10px);
      border: 10px solid transparent;
      border-top: 12px solid white;
      transform: translate(${({ offset }) => -offset}px, 0);
    }
  }
`

const FactorsChart = ({
  width = 700,
  height = 440,
  mode = "light",
  selectedItems = [],
  barWidth = 8,
  highlightColor = "grey1",
  padding = { left: 30, right: 20, top: 5, bottom: 5 },
  domainPadding = { x: 25, y: 35 },
}) => {
  const categories = [
    "Culture of innovation",
    "Dedicated funding",
    "Strong focus on data",
    "Human Resources",
    "A strong team",
    "Support from broader community",
    "Leadership commitment",
    "Engagement with partners",
  ]

  const categoriesSlugs = [
    "culture_of_innovation",
    "dedicated_funding",
    "strong_focus_on_data",
    "human_resources",
    "a_strong_team",
    "support_from_broader_community",
    "leadership_commitment",
    "engagement_with_partners",
  ]

  const colorScale = ["#FFF"]

  const overviewData = useChartData()

  const data = [[]]
  for (let i = 0; i < categories.length; i++) {
    const category = categories[i]
    const categorySlug = categoriesSlugs[i]
    data[0].push({
      x: category,
      y: overviewData.lackingFactors[categorySlug],
      label: "",
    })
  }

  const maxDomains = []
  let i = 0
  for (let category of categories) {
    maxDomains[i] = data[0][i].y
    i++
  }
  const maxDomain = Math.max(...maxDomains)

  // const policiesData = get(useOverview(), "policies.all") || [];
  // const data = policiesData.map(policy => ({
  //   x: policy.title,
  //   y: policy.total,
  // }))
  return (
    <TrackVisibility once partialVisibility>
      {({ isVisible }) =>
        isVisible && (
          <>
            <VictoryChart
              categories={{ x: categories }}
              animate={{ duration: 500, easing: "polyInOut" }}
              width={width}
              height={height}
              // padding={padding}
              maxDomain={{ x: categories.length, y: maxDomain }}
              domainPadding={domainPadding}
              style={{
                parent: {
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  left: 0,
                  top: 0,
                  touchAction: "auto",
                },
              }}
            >
              <VictoryStack colorScale={colorScale}>
                {data.map((step, index) => (
                  <VictoryBar
                    key={index}
                    barWidth={width / 36}
                    data={step}
                    categories={{ x: categories }}
                    labelComponent={
                      <VictoryTooltip
                        flyoutComponent={
                          <GraphTooltip
                            selectedItems={selectedItems}
                            highlightColor={highlightColor}
                          />
                        }
                      />
                    }
                  ></VictoryBar>
                ))}
              </VictoryStack>
              <VictoryAxis
                dependentAxis
                style={styles[mode].yAxis}
                label="% of cities"
              />
              <VictoryAxis
                tickFormat={categories}
                style={styles[mode].xAxis}
                tickLabelComponent={
                  <VictoryLabel
                    width={17}
                    y={355}
                    text={datum => {
                      return categories[datum.index].split(" ")
                    }}
                  />
                }
              />
            </VictoryChart>
          </>
        )
      }
    </TrackVisibility>
  )
}

const Legend = styled.div`
  display: flex;
  justify-content: space-around;
  font-family: ${({ theme }) => theme.fonts["roboto"]};
  font-size: 0.85rem;
  font-weight: 600;
  div {
    padding-left: 24px;
    position: relative;
  }

  div::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  div:nth-child(1)::before {
    background-color: rgb(255, 255, 255);
  }

  div:nth-child(2)::before {
    background-color: rgb(203, 214, 226);
  }

  div:nth-child(3)::before {
    background-color: rgb(128, 156, 183);
  }
  /* const colorScale = ["#FFF", "rgb(203, 214, 226)", "rgb(128, 156, 183)"] */
`

const styles = {
  light: {
    yAxis: {
      axisLabel: {
        fontFamily: "Roboto Condensed",
        fill: "white",
        padding: 30,
        x: -10,
      },
      axis: {
        stroke: "white",
      },
      grid: {
        stroke: "#dddee0",
        opacity: "0.55",
        strokeWidth: 1,
      },
      tickLabels: {
        fill: "white",
        fontSize: 12,
        fontFamily: "Roboto Condensed",
        fontWeight: "bold",
      },
    },
    xAxis: {
      axis: {
        stroke: "white",
      },
      grid: {
        stroke: "transparent",
      },
      tickLabels: {
        fill: "white",
        fontSize: 12,
        fontFamily: "Roboto Condensed",
        fontWeight: "bold",
      },
    },
  },
  dark: {
    yAxis: {
      axis: {
        stroke: "#dddee0",
      },
      grid: {
        stroke: "#dddee0",
        opacity: "0.55",
        strokeWidth: 1,
      },
      tickLabels: {
        fill: theme.grey1,
        fontSize: 12,
        fontFamily: "Roboto Condensed",
        fontWeight: "bold",
      },
    },
    xAxis: {
      axis: {
        stroke: "#dddee0",
      },
      grid: {
        stroke: "transparent",
      },
    },
  },
}

export default FactorsChart
