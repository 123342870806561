import React, { useState, useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import PercentCircle from "./PercentCircle"
import ReactMarkdown from "react-markdown"
import get from "lodash/get"

export const KeyFindingsTemplate = ({ title, topics }) => {
  const [activeTopic, setActiveTopic] = useState(0)
  const intervalId = useRef(null)

  function startInterval() {
    if (typeof window !== "undefined") {
      intervalId.current = window.setInterval(() => {
        setActiveTopic(old => (old + 1) % topics.length)
      }, 6000)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      startInterval()
      return () => {
        if (typeof window !== "undefined") {
          window.clearInterval(intervalId.current)
        }
      }
    }
  }, [])

  return (
    <>
      <div className="overview__background" />
      <Title>{title}</Title>
      <div className="overview__inner relative">
        <div className="overview__flag right" />
        <div className="container">
          <Wrapper>
            <div className="columns">
              <div className="column column-4 topic-text large">
                {topics[activeTopic].text}
                <div className="buttons">
                  <button
                    onClick={() => {
                      setActiveTopic(old => old - 1)
                      if (typeof window !== "undefined") {
                        window.clearInterval(intervalId.current)
                      }
                    }}
                    disabled={activeTopic == 0}
                  >
                    &lt;
                  </button>
                  <button
                    onClick={() => {
                      setActiveTopic(old => old + 1)
                      if (typeof window !== "undefined") {
                        window.clearInterval(intervalId.current)
                      }
                    }}
                    disabled={activeTopic == topics.length - 1}
                  >
                    &gt;
                  </button>
                </div>
              </div>
              <div className="column column-8">
                <div className="overview-block">
                  <div className="overview-block__inner">
                    {topics.map((topic, index) => (
                      <>
                        <div
                          className="category"
                          style={{
                            transform: `translateX(${-100 * activeTopic}%)`,
                          }}
                        >
                          {topic.categories.map((category, i) => (
                            <div className="category-row" key={i}>
                              <div className="category-title">
                                <h3>{category.title}</h3>
                                <small>{category.criteria}</small>
                              </div>
                              <div className="category-percent percent-1">
                                <p className="number">{category.percent_1} %</p>
                                <small>{category.percent_1_label}</small>
                              </div>
                              {category.percent_2 && (
                                <div className="category-percent percent-2">
                                  <p className="number">
                                    {category.percent_2} %
                                  </p>
                                  <small>{category.percent_2_label}</small>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
        </div>
      </div>
    </>
  )
}

const Title = styled.h2`
  text-align: center;
  margin-bottom: 3rem;
`

const Wrapper = styled.div`
  .columns {
    .topic-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: bold;
      color: rgb(89, 91, 101);
      font-family: ${({ theme }) => theme.fonts["roboto"]};
      line-height: 1.2;

      .buttons {
        margin: 0 auto;
        button {
          background-color: white;
          border: none;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          padding: 4px 8px;
          transition: all ease 0.3s;
          margin: 0;
          &:hover {
            cursor: pointer;
            background-color: #f8f8f8;
          }
          &:first-child {
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;
            border-left: 1px solid #ccc;
          }
          &:last-child {
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;
            border-right: 1px solid #ccc;
          }
        }
      }
    }

    .overview-block,
    .overview-flag {
      background-color: rgb(81, 60, 117);
    }

    .overview-block__inner {
      overflow: hidden;
      display: flex;
      min-width: 100%;
      max-width: 100%;
      font-family: ${({ theme }) => theme.fonts["roboto"]};

      .category {
        position: relative;
        min-width: 100%;
        max-width: 100%;
        transition: transform 0.3s ease;
        .category-row {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

          .number {
            display: inline;
            font-size: 2rem;
            font-weight: bold;
            margin: 0.25rem auto;
            margin-top: 2rem;
            user-select: none;
            padding: 0 24px;
            border-radius: 2rem;
          }

          small {
            font-size: 0.85rem;
            font-weight: 600;
            margin-top: 8px;
          }

          .percent-1 .number {
            background-color: rgb(145, 66, 122);
          }
          .percent-2 .number {
            background-color: rgb(12, 0, 82);
          }

          &:not(:last-child) {
            margin-bottom: 64px;
          }

          & > div {
            width: 200px;
            display: flex;
            flex-direction: column;
            margin: auto;
            @media screen and (max-width: 920px) {
              grid-column: span 3;
            }
            &.category-title {
              h3 {
                position: relative;
                margin-bottom: 20px;
                &::before {
                  display: block;
                  content: "";
                  position: absolute;
                  bottom: -15px;
                  left: 50%;
                  width: 130px;
                  height: 10px;
                  transform: translateX(-50%);
                  background-image: url(/img/keyFindingsArrow.svg);
                  background-repeat: no-repeat;
                  background-position: 50% 50%;
                  background-size: 100% 100%;
                }
              }
              small {
                font-weight: 600;
                padding: 0 3rem;
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
`

const KeyFindings = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { slug: { eq: "key-findings" } }) {
        frontmatter {
          title
          topics {
            text
            categories {
              title
              criteria
              percent_1
              percent_1_label
              percent_2
              percent_2_label
            }
          }
        }
      }
    }
  `)
  const { title, topics } = get(data, "markdownRemark.frontmatter")
  if (!topics) return null
  return <KeyFindingsTemplate title={title} topics={topics} />
}

export default KeyFindings
